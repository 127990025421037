<template>
    <div class="notification-permissions__switch">
        <div class="notification-permissions__switch-label">
            <span>Sound Permissions</span>

            <label class="switch">
                <input type="checkbox" @change="updatePermissions" :checked="permissions.soundPermission" disabled />

                <span class="slider round"></span>
            </label>
        </div>
        <span class="notification-permissions__switch-description">
            Permission to receive notifications with sound, <br> check guide how allow sound permissions
        </span>
    </div>
</template>

<script setup>
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { notificationPermissionService } from '../Services/NotificationPermissionsService.js'
import { onMounted } from 'vue'

const { permissions } = useNotificationPermissionsStore()
const { setNotificationsPermissionsService } = notificationPermissionService()
const soundNotification = new Audio(require('@/assets/sounds/level-up-191997.mp3'))

onMounted(() => { updatePermissions() })

async function updatePermissions() {
    // * Set volume value to permissions object
    try {
        soundNotification.volume = 0.00001
        await soundNotification.play()

        permissions.soundPermission = true
    } catch (error) {
        permissions.soundPermission = false
    }

    // * Dispatch notification permissions service
    const response = await setNotificationsPermissionsService(permissions)

    // * Return errors in console
    if (response.hasErrors) return console.error(`Updating permissions error: ${response.message}`)
}
</script>