import { useClientStore } from '@/stores/ClientStore.js'
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'

export function newMessagesNotificationsEvent() {
    const { client } = useClientStore()
    const { permissions } = useNotificationPermissionsStore()
    const filesPath = process.env.VUE_APP_FILES_PATH
    const soundNotification = new Audio(require('@/assets/sounds/level-up-191997.mp3'))

    // * Subscribe to new message notification event
    function joinNewMessageNotificationEvent() {
        console.log('Join in new messages channel ✅')

        Echo.private(`new-message-notification.${client.id}`)
            .listen(".message", async (message) => {
                // * Set config notification
                const notificationTitle = message.associateName;
                const notificationOptions = {
                    body: message.messageText,
                    icon: `${filesPath}${message.associateImagePath}`,
                    requireInteraction: true,
                    silent: true
                }

                // * Show notification
                const notification = new Notification(notificationTitle, notificationOptions)

                // * Show console error if notification hava a error
                notification.addEventListener('error', (error) => {
                    console.error(`Error showing notification: ${error}`)
                })

                // * Close notification instance
                notification.addEventListener("close", (n) => {
                    n.target.close()
                })

                // * play custom notification sound
                soundNotification.volume = permissions.soundVolume
                soundNotification.play();
            })
    }

    // * Leave to new message notification event
    function leaveNewMessageNotificationEvent() {
        Echo.leave(`new-message-notification.${client.id}`)
    }

    return { joinNewMessageNotificationEvent, leaveNewMessageNotificationEvent }
}