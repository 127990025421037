<template>
    <div class="home">
        <div class="home__filter">
            <h1 class="home__title">Rooms Status</h1>
            <InputFilter v-model="filterWords" />
        </div>

        <div class="home__room-headers">
            <div>Floor</div>
            <div>Room</div>
            <div>Associates</div>
            <div>Status</div>
        </div>

        <div class="home__room-list">
            <div class="home__room-info" v-for="room in rooms" :key="room.id" @click="setRoomObj(room)">
                <div class="home__room-floor">
                    {{ room.floorName }}
                </div>

                <div class="home__room-name">
                    {{ room.roomName }}
                </div>

                <div class="home__room-associates">
                    <img class="home__associate-image" :src="filesPath + associateInfo.associateImage"
                        :title="associateInfo.associateName" v-for="associateInfo in room.associatesInfo" />

                    <span v-if="room.extraAssociatesNumber">{{ room.extraAssociatesNumber }}+</span>

                    <span class="home__total-associates" v-if="room.associatesInfo.length > 3">
                        {{ room.associatesInfo.length }}+
                    </span>

                    <span v-if="!room.associatesInfo.length">
                        Empty
                    </span>
                </div>

                <div class="home__room-status">
                    <img src="@/assets/icons/dot-green.svg" class="table__icon-status"
                        v-if="room.statusId == 1 || room.statusId == 7" />
                    <img src="@/assets/icons/dot-yellow.svg" class="table__icon-status" v-if="room.statusId == 2" />

                    {{ room.statusName }}
                </div>
            </div>

            <!-- Empty table records component -->
            <TableEmptyData title="Rooms List Empty" message="We did not find rooms in your list..."
                v-if="!rooms.length" />
        </div>
    </div>


    <ContentLoader :hidden-loader="hiddenLoader" />

    <Notifications ref="notification" />
</template>

<script setup>
import { homeServices } from '../Services/HomeServices.js'
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue'
import Notifications from '../../../common/Notifications.vue'
import { useClientStore } from '../../../stores/ClientStore'
import ContentLoader from '@/common/ContentLoader.vue'
import InputFilter from '@/common/InputFilter.vue';
import TableEmptyData from '@/common/TableEmptyData.vue'

const filesPath = process.env.VUE_APP_FILES_PATH
const { getRooms } = homeServices()
const notification = ref(null)
const clientStore = useClientStore()
const emits = defineEmits(['roomObj'])
const path = (words) => { return `/web/home?page=1&query=${words}` }
const hiddenLoader = ref(false)
const rooms = ref([])
const filterWords = ref("")
const nextPage = ref()
const associatesImage = ref([])

onMounted(async () => {
    // * Get rooms list
    const rooms = await getRoomsList()

    // * Set rooms list
    setRoomsList(rooms)

    // * Join in qrScanMobileEvent event
    qrScanMobileEvent()
})

onUnmounted(() => {
    Echo.leave(`scan-qr.${clientStore.client.hotelId}`)
})

watch(filterWords, async () => {
    // * Set nextPage to null
    nextPage.value = null

    // * Get rooms list
    const roomsData = await getRoomsList()

    // * Reset rooms list array
    rooms.value = []

    // * Set rooms list
    setRoomsList(roomsData)
})

// * Update status room when associate scann QR room
async function qrScanMobileEvent() {
    Echo.private(`scan-qr.${clientStore.client.hotelId}`)
        .listen(".scanned-qr", async (roomEvent) => {
            // * Set nextPage to null
            nextPage.value = null

            // * Get rooms list
            const roomsData = await getRoomsList()

            // * Reset rooms list array
            rooms.value = []

            // * Set rooms list
            setRoomsList(roomsData)
        })
}

// * Get rooms data
async function getRoomsList() {
    // * Define roomsListPath path
    const roomsListPath = nextPage.value ? nextPage.value.split(`${process.env.VUE_APP_BASE_PATH}/api`)[1] : path(filterWords.value)

    // * Get rooms data
    const roomsList = await getRooms(roomsListPath)

    // * Show error notification
    if (roomsList.hasErrors) {
        notification.value.sendNotification('error', 'Internal Server Error.')
        return console.error(`Home service error: ${roomsList.message}`)
    }

    return roomsList.data
}

async function setRoomsList(roomsData) {
    // * Stop process if not have rooms
    if (!roomsData.data.length) return

    // * Set next page url
    nextPage.value = roomsData.next_page_url

    // * Set rooms logs records
    rooms.value.push(...roomsData.data)

    // * Load dom data
    await nextTick()

    // * Call intersection observer function
    intersectionOberserver()

    hiddenLoader.value = true
}

// * Intersection Observer function 
function intersectionOberserver() {
    const rootElement = document.querySelector(".home__room-list")
    const observaleElement = Array.from(document.querySelectorAll(".home__room-info")).pop()

    // * Observer options
    const options = {
        root: rootElement,
        rootMargin: "0px 0px 90% 0px",
    }

    // * Make observer element
    const roomsListObserver = new IntersectionObserver(intersectedElement, options)

    // * Init observer element
    roomsListObserver.observe(observaleElement)
}

// * Callback observable element
async function intersectedElement(entrie, observer) {
    const [{ isIntersecting, target }] = entrie

    // * Set intersection into new group rooms
    if (isIntersecting && nextPage.value) {
        // * Get rooms list
        const rooms = await getRoomsList()

        // * Set rooms list
        setRoomsList(rooms)
    }

    // * Stop observer element
    if (isIntersecting && !nextPage.value) observer.unobserve(target)
}

// * Emit event roomObj
function setRoomObj(room) {
    const roomValues = {
        roomId: room.id,
        roomName: room.roomName,
    }

    emits('roomObj', roomValues)
}

// function setAssociatesImage(assocciates) {
//     console.log(assocciates)
// }
</script>