<template>
    <div class="notification-permissions" v-show="showModalPermissions">
        <div class="notification-permissions__content">
            <h1 class="notification-permissions__title">
                SafeHub Permissions
            </h1>

            <div class="notification-permissions__switchs">
                <NotificationPermissionSwitch />
                <SoundPermissionSwitch />
                <SoundSwitch />
            </div>

            <a href="https://www.youtube.com/watch?v=VX8FDN2PPro" class="notification-permissions__guide-link" target="_blank">
                Show Me How
            </a>

            <!-- <a href="./files/user_guide_permissions.pdf" target="_blank" class="notification-permissions__guide-link">
                <img src="@/assets/icons/vuesax/linear/setting-3.svg" alt="">
                Show Me How
            </a> -->

            <button class="button button--primary" @click="permissionsStore.modalPermissions">
                Close SafeHub Permissions
            </button>
        </div>
    </div>
</template>

<script setup>
import SoundSwitch from '../Components/SoundSwitch.vue'
import NotificationPermissionSwitch from '../Components/NotificationPermissionSwitch.vue'
import SoundPermissionSwitch from '../Components/SoundPermissionSwitch.vue'
import { useNotificationPermissionsStore } from '@/stores/NotificationPermissionsStore.js'
import { computed } from 'vue'

const permissionsStore = useNotificationPermissionsStore()
const showModalPermissions = computed(() => { return permissionsStore.showModalPermissions })
const notificationPermission = computed(() => { return permissionsStore.permissions.notificationPermission })
const soundPermission = computed(() => { return permissionsStore.permissions.soundPermission })
</script>